/*
 * -------------------------------------------------------
 * THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 * -------------------------------------------------------
 */

/* tslint:disable */

/* eslint-disable */

export class Customer {
  number: string
  name: string
  deliveryAddress?: Nullable<DeliveryAddress>
  serviceCenterId?: Nullable<string>
}

export class DeliveryAddress {
  name?: Nullable<string>
}

export abstract class IQuery {
  abstract customer(number: string): Nullable<Customer> | Promise<Nullable<Customer>>

  abstract deliveries(
    customerNumber: string,
    dateFrom?: Nullable<string>,
    dateTill?: Nullable<string>,
    searchFor?: Nullable<string>
  ): Nullable<Nullable<Delivery>[]> | Promise<Nullable<Nullable<Delivery>[]>>

  abstract invoices(
    number: String,
    dateFrom: String,
    dateTill: String,
    searchFor: String
  ): Nullable<Nullable<Invoice>[]> | Promise<Nullable<Nullable<Invoice>[]>>
}

export class Delivery {
  deliveryDate?: Nullable<string>
  shippingType?: Nullable<string>
  deliveryCost?: Nullable<number>
  minimumAmountForNoDeliveryCost?: Nullable<number>
  netDeliveryAmountExclTax?: Nullable<number>
  netDeliveryAmountInclTax?: Nullable<number>
  deliveryStatus?: Nullable<DeliveryStatus>
  pickupInformation?: Nullable<PickUpInformation>
  directDeliveryInformation?: Nullable<DirectDeliveryInformation>
  orders?: Nullable<Nullable<Order>[]>
}

export class DeliveryStatus {
  deliveredBy?: Nullable<string>
  status?: Nullable<Status>
  deliveryMoment?: Nullable<DeliveryMoment>
}

export class PickUpInformation {
  pickupPartOfDay?: Nullable<string>
  pickupTimeFrom?: Nullable<string>
  pickupTimeTill?: Nullable<string>
  pickupAddress?: Nullable<Address>
}

export class DirectDeliveryInformation {
  supplierName?: Nullable<string>
}

export class Order {
  orderNumber?: Nullable<string>
  orderNumberATG?: Nullable<string>
  orderDate?: Nullable<string>
  customerReference?: Nullable<string>
  status?: Nullable<Status>
  deleted?: Nullable<boolean>
  numberOfProductsNotDelivered?: Nullable<number>
}

export class DeliveryMoment {
  from?: Nullable<string>
  till?: Nullable<string>
  wasChanged?: Nullable<boolean>
  isDeliveryTimeUpdated?: Nullable<boolean>
}

export class Status {
  name?: Nullable<string>
  number?: Nullable<number>
  maxStateNumber?: Nullable<number>
}

export class Address {
  street?: Nullable<string>
  postalCode?: Nullable<string>
  city?: Nullable<string>
}

export class Invoice {
  invoiceNumber?: Nullable<string>
  invoiceDate?: Nullable<string>
  netInvoiceAmountInclTax?: Nullable<number | string>
  orders?: Nullable<Nullable<InvoiceOrder>[]>
}

export class InvoiceOrder {
  deliveryDate?: Nullable<string>
  orderNumber?: Nullable<string>
  orderNumberATG?: Nullable<string>
  customerReference?: Nullable<string>
}

type Nullable<T> = T | null


export enum QuickFilter {
  THIS_WEEK = 'This week',
  LAST_WEEK = 'Last week',
  LAST_30_DAYS = 'Last 30 Days'
}


export enum Preference {
  INVOICES = 'CP_INVOICES',
  TRANSPORT_DOCUMENTS = 'CP_TRANSPORT_DOCUMENT',
  PICK_CORRECTIONS = 'CP_PICK_CORRECTIONS',
  ARTICLES_OVERVIEW = 'CP_ARTICLE_OVERVIEWS',
  ORDER_CONFIRMATIONS = 'CP_ORDER_CONFIRMATIONS'
}

export enum DocumentKey {
  FACTUUR = 'FACTUUR',
  PAKBON = 'PAKBON',
  VRACHTBRIEF = 'VRACHTBRIEF',
  RETOURBEVESTIGING = 'RETOURBEVESTIGING',
  PICKCORRECTIE = 'PICKCORRECTIE',
  ARTIKELOVERZICHT = 'ARTIKELOVERZICHT',
}

export interface ContactPreferenceDocumentSection {
  documentKey?: DocumentKey
  translationKey: string
}

export enum ContactPreferenceType  {
  LOCATION =  'LOCATION',
  PERSONAL = 'PERSONAL'
}

export interface PopupPreference {
  contactPreferenceType: ContactPreferenceType
  documentName: string,
  minEmail?: number,
  maxEmail?: number,
  documentSections?: ContactPreferenceDocumentSection[]
}
